import { CAButton } from '@careacademy/ca-design-system'
import Vue, { defineComponent } from 'vue'
import VModal from 'vue-js-modal'

import { on } from '@/javascript/vuejs/helpers/emitEventHandler'
import GlobalStore from '@/javascript/vuejs/stores/GlobalStore'

Vue.use(VModal, {
	dynamic: true,
	injectModalsContainer: true,
})

/* eslint-disable @typescript-eslint/no-unused-vars */
const emits = {
	'back': (): boolean => true,
	'closed': (): boolean => true,
	'next': (): boolean => true,
	'opened': (): boolean => true,
	'save': (event?: Event): boolean => true,
}
/* eslint-enable */

export default defineComponent({
	name: 'StandardModalButtonContainer',

	props: {
		...on(emits),

		actionButtonText: { default: 'Save', type: String },
		backButtonText: { default: 'Back', type: String },
		closeButtonText: { default: 'Cancel', type: String },
		hideActionButton: { default: false, type: Boolean },
		hideCloseButton: { default: false, type: Boolean },
		isActionDestructive: { default: false, type: Boolean },
		isBackDisabled: { default: false, type: Boolean },
		isCancelDisabled: { default: false, type: Boolean },
		isDisabled: { default: false, type: Boolean },
		modalName: { required: true, type: String },
		showBackButton: { default: false, type: Boolean },
		showNextButton: { default: false, type: Boolean },
		showProcessingIcon: { default: false, type: Boolean },
		useAlternateLayout: { default: false, type: Boolean },
	},

	emits,

	computed: {
		buttonType(): string {
			if (this.useAlternateLayout) {
				return 'primary'
			}

			return this.isActionDestructive ? 'danger' : 'primary'
		},
	},

	methods: {
		closeModal() {
			this.$modal.hide(this.modalName)
			this.$emit('closed')
		},

		goBack() {
			this.$emit('back')
		},

		goNext() {
			this.$emit('next')
		},

		save() {
			this.$emit('save')
		},
	},

	render() {
		return (
			<div class="standard-modal__button-container standard-modal__button-container-center" data-test="standard-modal-button-container">
				<div class={
					this.useAlternateLayout ? 'standard-modal__button-container-layout' : GlobalStore.useDesignSystem ?
						'modal__actions modal__actions-center' : 'flex gap-1'
				}>
					{this.showBackButton &&
						<CAButton
							buttonStyle="filled-light"
							disabled={this.isBackDisabled}
							icon="SVGChevronLeft"
							type="secondary"
							onClick={this.goBack}
						>
							{this.backButtonText}
						</CAButton>
					}

					{!this.hideCloseButton &&
						<CAButton
							buttonStyle="filled-light"
							dataTest="modal-close-button"
							disabled={this.isCancelDisabled}
							type="secondary"
							onClick={this.closeModal}
						>
							{this.closeButtonText}
						</CAButton>
					}

					{this.showNextButton ?
						<CAButton
							class="ml-4"
							dataTest="step-forward-next-button"
							disabled={this.isDisabled}
							type="primary"
							onClick={this.goNext}
						>
							{this.actionButtonText}
						</CAButton>

						:

						<span>
							{!this.hideActionButton &&
								<CAButton
									class="ml-4"
									dataTest="modal-action-button"
									disabled={this.isDisabled}
									loading={this.showProcessingIcon}
									type={this.buttonType}
									onClick={this.save}
								>
									{this.actionButtonText}
								</CAButton>
							}
						</span>
					}
				</div>
			</div>
		)
	},
})
